@tailwind base;
@tailwind components;
@tailwind utilities;

/* These `thumb` styles are for the handles on the range slider */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #3EABBF;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #317D82;
}
input[type=range]:disabled::-webkit-slider-thumb {
  background-color: #b8c9cc;
  box-shadow: 0 0 0 1px #b8c9cc;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #3EABBF;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #317D82;
}
input[type=range]:disabled::-moz-range-thumb {
  background-color: #b8c9cc;
  box-shadow: 0 0 0 1px #b8c9cc;
}

body {
  margin: 0;
  font-family: "Libre Franklin", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   @apply text-gray-800;
}

.clip-circle {
  clip-path: circle(25px at center);
}

/* ActiveNET includes HTML tags in their descriptions */
/* Spacing under each div */
.activenet-description div {
  margin-bottom: 1rem;
}
/* Spacing under each child of a div */
.activenet-description div > * + * {
  margin-bottom: 1rem;
}
.activenet-description li {
  list-style-type: disc;
  margin-left: 1.75rem;
}
.activenet h4 {
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}
